export function addUAClass() {
  
  let UA = window.navigator.userAgent;
  let ua = UA.toLocaleLowerCase();
  
  let bodyElm = document.getElementsByTagName('body')[0];
  
  let MobileDetect = require('mobile-detect');
  let md = new MobileDetect(UA);
  
  let os;
  let mobile;
  var tablet;
  var viewportContent;
  
  if(md.mobile() && !md.tablet()){
    mobile = 'isSP';
    os = md.os().toLocaleLowerCase();
    bodyElm.classList.add(mobile,os);
    viewportContent = "width=device-width,initial-scale=1";

  }else if(md.tablet()){
    tablet = 'isTABLET';
    os = md.os().toLocaleLowerCase();
    bodyElm.classList.add(tablet,os);
    viewportContent = "width=1100px,user-scalable=no,shrink-to-fit=yes";
  }else{
    bodyElm.classList.add('isPC');
    viewportContent = "width=device-width,user-scalable=no,shrink-to-fit=yes";
  }
  
  if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
    bodyElm.classList.add('ie');
  } else if (ua.indexOf("edg") != -1 || ua.indexOf("edge") != -1) {
    bodyElm.classList.add('edge');
  } else if (ua.indexOf("opr") != -1 || ua.indexOf("opera") != -1) {
    bodyElm.classList.add('opera');
  } else if (ua.indexOf("chrome") != -1) {
    bodyElm.classList.add('chrome');
  } else if (ua.indexOf("safari") != -1) {
    bodyElm.classList.add('safari');
  } else if (ua.indexOf("firefox") != -1) {
    bodyElm.classList.add('firefox');
  } else if (ua.indexOf("opr") != -1 || ua.indexOf("opera") != -1) {
    bodyElm.classList.add('opera');
  }
  
  document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent);
  
  // console.log(viewportContent)
  
  // console.log('Mobile? : ' + md.mobile() + ' | Tabet? : ' + md.tablet()  + ' | UA? : ' + md.userAgent() + ' | OS？' + md.os());

//debug CODE
//   var debugText = 'Mobile? : ' + md.mobile() + ' | Tabet? : ' + md.tablet()  + ' | UA? : ' + md.userAgent() + ' | OS？' + md.os();
//   document.getElementById('debugText').textContent = debugText ;
}


